import { Sizes, SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "@inferno/renderer-shared-ui";
import { ResponsiveImage } from "../ResponsiveImage.component";
import type { ImageSource } from "./LiveCard.component";
import { LiveCardType } from "./LiveCardType";
import { RESPONSIVE_IMAGE } from "../../ui/constants";

interface ThumbProps {
  imgUrl: string;
  source: ImageSource;
  type: string;
  url?: string;
  alt?: string;
  target?: string;
}

const imgQuality = 75;

export const Thumb = ({ imgUrl, source, type, url = "", alt = "", target }: ThumbProps) => {
  let srcSet: SrcSet[] = [];
  let sizes: Sizes[] = [];
  let placeholderSrcset: SrcSet[] = [];
  let imgOps = `?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`;
  switch (type) {
    case LiveCardType.ONAIR:
      // I hate everything about this, but it's the best I could come up with for this problem
      if (source === "station") {
        imgOps = `?ops=gravity("center"),contain(190,190),quality(${imgQuality})`;
        srcSet = [
          {
            url: `${imgUrl}?ops=gravity("center"),contain(190,190),quality(${imgQuality})`,
            descriptor: "190w",
          },
          {
            url: `${imgUrl}?ops=gravity("center"),contain(360,360),quality(${imgQuality})`,
            descriptor: "360w",
          },
          {
            url: `${imgUrl}?ops=gravity("center"),contain(225,225),quality(${imgQuality})`,
            descriptor: "225w",
          },
          {
            url: `${imgUrl}?ops=gravity("center"),contain(170,170),quality(${imgQuality})`,
            descriptor: "170w",
          },
          {
            url: `${imgUrl}?ops=gravity("center"),contain(300,300),quality(${imgQuality})`,
            descriptor: "300w",
          },
        ];
        sizes = [
          {
            media: "(max-width: 420px)",
            size: "190px",
          },
          {
            media: "(max-width: 768px)",
            size: "360px",
          },
          {
            media: "(max-width: 1060px)",
            size: "225px",
          },
          {
            media: "(max-width: 1440px)",
            size: "170px",
          },
          {
            media: "(min-width: 1441px)",
            size: "300px",
          },
          {
            media: "auto",
            size: "",
          },
        ];
        placeholderSrcset = [
          {
            url: `${placeholderImage}?ops=gravity("center"),contain(190,190),quality(${imgQuality})`,
            descriptor: "190w",
          },
          {
            url: `${placeholderImage}?ops=gravity("center"),contain(360,360),quality(${imgQuality})`,
            descriptor: "360w",
          },
          {
            url: `${placeholderImage}?ops=gravity("center"),contain(225,225),quality(${imgQuality})`,
            descriptor: "225w",
          },
          {
            url: `${placeholderImage}?ops=gravity("center"),contain(170,170),quality(${imgQuality})`,
            descriptor: "170w",
          },
          {
            url: `${placeholderImage}?ops=gravity("center"),contain(300,300),quality(${imgQuality})`,
            descriptor: "300w",
          },
        ];
        break;
      }

      imgOps = `?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`;
      srcSet = [
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`,
          descriptor: "190w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(360,360),quality(${imgQuality})`,
          descriptor: "360w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(225,225),quality(${imgQuality})`,
          descriptor: "225w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(170,170),quality(${imgQuality})`,
          descriptor: "170w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(300,300),quality(${imgQuality})`,
          descriptor: "300w",
        },
      ];
      sizes = [
        {
          media: "(max-width: 420px)",
          size: "190px",
        },
        {
          media: "(max-width: 768px)",
          size: "360px",
        },
        {
          media: "(max-width: 1060px)",
          size: "225px",
        },
        {
          media: "(max-width: 1440px)",
          size: "170px",
        },
        {
          media: "(min-width: 1441px)",
          size: "300px",
        },
        {
          media: "auto",
          size: "",
        },
      ];
      placeholderSrcset = [
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`,
          descriptor: "190w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(360,360),quality(${imgQuality})`,
          descriptor: "360w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(225,225),quality(${imgQuality})`,
          descriptor: "225w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(170,170),quality(${imgQuality})`,
          descriptor: "170w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(300,300),quality(${imgQuality})`,
          descriptor: "300w",
        },
      ];

      break;
    case LiveCardType.RECENT:
    case LiveCardType.TOPSONG:
      srcSet = [
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`,
          descriptor: "190w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(360,360),quality(${imgQuality})`,
          descriptor: "360w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(225,225),quality(${imgQuality})`,
          descriptor: "225w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(170,170),quality(${imgQuality})`,
          descriptor: "170w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(300,300),quality(${imgQuality})`,
          descriptor: "300w",
        },
      ];
      sizes = [
        {
          media: "(max-width: 420px)",
          size: "190px",
        },
        {
          media: "(max-width: 768px)",
          size: "360px",
        },
        {
          media: "(max-width: 1060px)",
          size: "225px",
        },
        {
          media: "(max-width: 1440px)",
          size: "170px",
        },
        {
          media: "(min-width: 1441px)",
          size: "300px",
        },
        {
          media: "auto",
          size: "",
        },
      ];
      placeholderSrcset = [
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(190,190),quality(${imgQuality})`,
          descriptor: "190w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(360,360),quality(${imgQuality})`,
          descriptor: "360w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(225,225),quality(${imgQuality})`,
          descriptor: "225w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(170,170),quality(${imgQuality})`,
          descriptor: "170w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(300,300),quality(${imgQuality})`,
          descriptor: "300w",
        },
      ];
      break;
    case LiveCardType.MICROSITE:
    default:
      srcSet = [
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(100,100),quality(${imgQuality})`,
          descriptor: "100w",
        },
        {
          url: `${imgUrl}?ops=gravity("center"),ratio(1,1),fit(200,200),quality(${imgQuality})`,
          descriptor: "200w",
        },
      ];
      sizes = [
        {
          media: "(max-width: 767px)",
          size: "100px",
        },
        {
          media: "(min-width: 768px)",
          size: "200px",
        },
        {
          media: "auto",
          size: "",
        },
      ];
      placeholderSrcset = [
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(100,100),quality(${imgQuality})`,
          descriptor: "100w",
        },
        {
          url: `${placeholderImage}?ops=gravity("center"),ratio(1,1),fit(200,200),quality(${imgQuality})`,
          descriptor: "200w",
        },
      ];
      break;
  }
  const placeHolder = `${placeholderImage}${imgOps}`;
  if (url) {
    return (
      <MagicLink to={url} target={target}>
        <ResponsiveImage
          src={`${imgUrl}${imgOps}`}
          srcset={srcSet}
          sizes={sizes}
          alt={alt}
          placeholder={placeHolder}
          placeholderSrcset={placeholderSrcset}
          initialWidth={RESPONSIVE_IMAGE.thumbImageWidth}
          initialHeight={RESPONSIVE_IMAGE.thumbImageHeight}
        />
      </MagicLink>
    );
  }
  return (
    <ResponsiveImage
      src={`${imgUrl}${imgOps}`}
      srcset={srcSet}
      sizes={sizes}
      alt={alt}
      placeholder={placeHolder}
      placeholderSrcset={placeholderSrcset}
      initialWidth={RESPONSIVE_IMAGE.thumbImageWidth}
      initialHeight={RESPONSIVE_IMAGE.thumbImageHeight}
    />
  );
};
