import classnames from "classnames";
import i18next from "i18next";
import { createElement, Fragment } from "react";
import { SocialNetworkData } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";
import { EqualizerIcon } from "../icons/EqualizerIcon.component";
import "./LiveCard.style.scss";
import { Thumb } from "./Thumb.component";

export enum LiveCardType {
  RECENT = "recent",
  TOPSONG = "topsong",
  MICROSITE = "microsite",
  ONAIR = "onair",
  LISTENING_EXPERIENCE = "listening",
}

export type ImageSource = "station" | "show" | "other";

export interface LiveCardImage {
  url: string | null | undefined;
  source: ImageSource;
}

export interface LiveCardProps {
  image: LiveCardImage;
  title: string;
  content?: JSX.Element | null;
  url?: string;
  alt?: string;
  social?: SocialNetworkData[];
  className?: string;
  type?: LiveCardType;
  equalizer?: boolean;
  rank?: number;
  h1?: boolean;
  target?: string;
}

interface TitleProps {
  title: string;
  type: string;
  url?: string;
  h1?: boolean;
  target?: string;
}

const Title = ({ title, type = "microsite", url = "", h1 = true, target = "_blank" }: TitleProps) => {
  let heading = null;
  switch (type) {
    case LiveCardType.ONAIR:
    case LiveCardType.RECENT:
    case LiveCardType.TOPSONG:
      heading = (
        <h2 className="livecard-title">
          {url ? (
            <MagicLink to={url} target={target}>
              {title}
            </MagicLink>
          ) : (
            title
          )}
        </h2>
      );
      break;
    case LiveCardType.MICROSITE:
    default:
      heading = (
        <Fragment>
          {createElement(
            h1 ? "h1" : "h2",
            { className: "livecard-title" },
            url ? (
              <MagicLink to={url} target={target}>
                {title}
              </MagicLink>
            ) : (
              title
            ),
          )}
        </Fragment>
      );
      break;
  }
  return heading;
};

const Equalizer = ({ text = i18next.t("equalizer_text") }: { text: string }) => (
  <section className="equalizer">
    <p>
      <EqualizerIcon />
      <span>{text}</span>
    </p>
  </section>
);

export const LiveCard = (props: LiveCardProps) => {
  const cardClass = classnames("component-live-card", {
    [`${props.className}`]: props.className,
    [`type-${props.type}`]: props.type,
    "with-thumb": props.image.url,
  });

  let eqText = i18next.t("equalizer_text");
  if (props.type === LiveCardType.RECENT) {
    eqText = i18next.t("now_playing");
  }

  const { url: thumbUrl, source: thumbSource } = props.image;
  const alt = props.alt || props.title;
  const { h1 = true } = props;

  if (!thumbUrl) {
    return (
      <div className={cardClass}>
        <Title
          title={props.title}
          h1={h1}
          type={props.type || LiveCardType.MICROSITE}
          url={props.url}
          target={props.target}
        />
        {props.content ? <section className="content">{props.content}</section> : null}
      </div>
    );
  }

  return (
    <figure className={cardClass}>
      {props.equalizer ? <Equalizer text={eqText} /> : null}
      {props.image?.url ? (
        <section className="thumb" data-rank={props.rank}>
          <Thumb
            imgUrl={thumbUrl || ""}
            source={thumbSource}
            type={props.type || LiveCardType.MICROSITE}
            url={props.url}
            alt={alt}
            target={props.target}
          />
        </section>
      ) : null}
      <figcaption>
        {props.rank ? <h2 className="song-rank">{props.rank}</h2> : null}
        <Title
          title={props.title}
          h1={h1}
          type={props.type || LiveCardType.MICROSITE}
          url={props.url}
          target={props.target}
        />
        {props.content ? <section className="content">{props.content}</section> : null}
      </figcaption>
    </figure>
  );
};
