import classnames from "classnames";
import { inject } from "mobx-react";
import { useEffect, useState, Fragment } from "react";

import type {
  MicrositeGeneralConfigFragment,
  MicrositeSocialConfigFragment,
  SitesTemplateInfernoMicrosite,
} from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { socialConfigAbstract } from "@inferno/renderer-shared-core";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { LiveCard, LiveCardImage } from "../livecard/LiveCard.component";
import { LiveCardType } from "../livecard/LiveCardType";
import "./MicrositeHeader.style.scss";
import type { MicrositeType } from "./MicrositeTypes";
import { trimText } from "../../lib/utilities";
import { MagicLink } from "../../ui";
import { useTranslation } from "react-i18next";
import { InfoIcon } from "../icons/InfoIcon.component";
import { useRouteMatch } from "react-router-dom";
import type { Store } from "@inferno/renderer-shared-core";
import { COMPONENT_BREAKPOINTS } from "../../ui/constants";

interface MicrositeHeaderProps {
  general?: MicrositeGeneralConfigFragment | null;
  social?: MicrositeSocialConfigFragment | null;
  type: MicrositeType;
  store?: Store;
  view_name?: string;
  slug?: string;
  config?: SitesTemplateInfernoMicrosite;
}

export const MicrositeHeader = inject("store")((props: MicrositeHeaderProps) => {
  const headerClass = classnames("microsite-header", {
    [`type-${props.type}`]: props.type,
  });
  const network = props.social ? socialConfigAbstract(props.social) : [];
  const title = props.general?.name || "";
  const url = props.slug ? `/featured/${props.slug}` : undefined;
  const image = props.general?.thumbnail_image?.id || "";
  const cardImage: LiveCardImage = {
    url: props.store?.env ? formatImage(image, props.store.env.IMAGE_HOST) : image,
    source: "station",
  };

  const description = props.config?.general?.description || "";
  const { full_bio } = props.config?.contact || {};
  const showMore = !!full_bio;
  const maxChars = 150;
  const { t } = useTranslation();
  const aboutRouteMatch = useRouteMatch({ path: `${url}/about`, exact: true });

  const [isMobile, setIsMobile] = useState(false);
  const handleWindowResize = () => {
    if (window.innerWidth < COMPONENT_BREAKPOINTS.mobileBreakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MiniBio = () => {
    return (
      <div className="miniBio">
        {showMore && !aboutRouteMatch ? (
          <MagicLink to={`/featured/${props.slug}/about`}>
            <InfoIcon />
            <span className="bio">{t("bio")}</span>
          </MagicLink>
        ) : null}
      </div>
    );
  };

  const HeaderDescription = () => {
    return (
      <Fragment>
        {!aboutRouteMatch && props.config?.general?.header_description ? (
          <div>
            {showMore ? (
              <p className="microsite-description">
                {trimText(description, maxChars)}
                <MagicLink to={`/featured/${props.slug}/about`}>
                  <span className="fullBio">{t("full_bio")}</span>
                </MagicLink>
              </p>
            ) : (
              <p className="microsite-description">{description}</p>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  };

  const content = (
    <div>
      {network.length > 0 ? <SocialIcons networks={network} /> : null}
      {isMobile ? <MiniBio /> : <HeaderDescription />}
    </div>
  );

  return (
    <LiveCard
      className={headerClass}
      content={content}
      image={cardImage}
      title={title}
      type={LiveCardType.MICROSITE}
      url={url}
      h1={props.view_name !== "detail"}
      target="_self"
    />
  );
});

export default MicrositeHeader;
