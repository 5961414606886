import "./EqualizerIcon.style.scss";

export const EqualizerIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon icon-equalizer"
  >
    <path
      tabIndex={-1}
      d="M12 20C13.1 20 14 19.1 14 18V6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6V18C10 19.1 10.9 20 12 20ZM6 20C7.1 20 8 19.1 8 18V14C8 12.9 7.1 12 6 12C4.9 12 4 12.9 4 14V18C4 19.1 4.9 20 6 20ZM16 11V18C16 19.1 16.9 20 18 20C19.1 20 20 19.1 20 18V11C20 9.9 19.1 9 18 9C16.9 9 16 9.9 16 11Z"
    />
  </svg>
);
